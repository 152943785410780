// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore/lite';
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDZZ5KIJekPigpjc_XRn9CjMLdpLGyCVtw",
  authDomain: "demoapp-11733.firebaseapp.com",
  projectId: "demoapp-11733",
  storageBucket: "demoapp-11733.appspot.com",
  messagingSenderId: "699246420239",
  appId: "1:699246420239:web:236b124cd916fb06201e7a"
};

// Initialize Firebase
export const firebaseApp = initializeApp(firebaseConfig);
export const db = getFirestore(firebaseApp);
export const auth = getAuth(firebaseApp)